@import "styles/color";

.group-card {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 7rem;
    height: 7rem;

    &.large {
        width: 100%;
        height: 10rem;
    }

    .group-card-content {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 100%;
        height: 100%;
    }

    h3 {
        margin-top: 0.3rem;
        font-size: 0.9rem;
        color: $gray-800;
    }
}
