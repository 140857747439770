@import "styles/color";

#layer-header {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 480px;

    @media (min-width: 480px) {
        border-left: 1px solid $gray-400;
        border-right: 1px solid $gray-400;
    }

    display: flex;
    justify-content: space-between;
    padding: 1rem 1.2rem 0.6rem;
    width: 100%;
    height: 4.5rem;
    background-color: $white;
    font-weight: bold;
    font-size: 1.1rem;

    .title-area {
        display: flex;
        flex-direction: row;
        align-items: center;

        .next-icon {
            margin-left: 0.5rem;
        }

        p {
            margin-left: 0.2rem;
            font-size: 1.3rem;
        }
    }

    h2 {
        color: $gray-800;

        &::after {
            content: "";
            display: block;
            margin-top: 6px;
            height: 4px;
            background-color: $green-800;
        }
    }

    .MuiButton-variantSolid {
        padding-left: 1rem;
        padding-right: 1rem;
        font-weight: bold;
        background-color: rgba($color: $green-800, $alpha: 0.7);
    }

    .MuiButton-variantSolid + .MuiButton-variantSolid {
        margin-left: 0.4rem;
    }
}
