@import "styles/color";

main {
    height: 100%;
    padding-top: 5rem;
    padding-bottom: 3.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

#root-nav {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 480px;

    border-top: 1px solid $gray-300;
    width: 100%;
    background-color: $white;

    @media (min-width: 480px) {
        border-left: 1px solid $gray-400;
        border-right: 1px solid $gray-400;
    }

    ul {
        display: flex;
        flex-direction: row;
    }

    li {
        flex: 1;

        a {
            text-align: center;
            display: inline-block;
            width: 100%;
            height: 3.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $gray-500;

            &.active {
                color: $gray-900;
                border-top: 1px solid $green-800;
            }
        }
    }
}
