@import "styles/color";

.add-group-modal {
    .new-group {
        margin-top: 1rem;
    }

    .group-list {
        display: flex;
        flex-wrap: wrap;
    }

    .empty-text {
        margin-top: 5rem;
        text-align: center;
        color: $gray-700;
    }

    .group-card-wrapper {
        margin-bottom: 0.5rem;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        width: calc(50% - 0.5rem);

        &:nth-child(2n) {
            margin-left: 0.5rem;
        }
    }
}

.add-group-modal {
    .MuiModalDialog-root {
        margin-top: 10rem;
        overflow-y: auto;
        background-color: $white;
        border-top-right-radius: 2rem !important;
        border-top-left-radius: 2rem !important;
    }
}
