@import "styles/color";

.following-card {
    cursor: pointer;
    position: relative;

    .name {
        margin-top: 0.5rem;

        font-weight: 500;
        font-size: 1.1rem;
        line-height: 1.3;
    }

    .desc {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        font-size: 0.8rem;
        line-height: 1.2;
        min-height: 2rem;
    }

    .select-item {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;

        &.selected-status {
            background-color: rgba($color: $gray-100, $alpha: 0.6);
        }

        padding: 1rem;
    }
}
