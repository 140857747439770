@import "styles/color";

.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10rem;

    .login-logo {
        width: 8rem;
    }

    .login-title {
        width: 10rem;
    }

    .login-btn,
    .register-btn {
        width: 17rem;
        height: 3rem;
        font-weight: bold;
        font-size: 1rem;
    }

    .login-btn {
        margin-top: 1rem;
        background-color: $green-800;
    }

    .register-btn {
        margin-top: 0.5rem;
        background-color: $green-100;
        color: $green-500;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    form {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        input {
            width: 17rem;
            height: 3rem;
            border: none;
            border-bottom: 1px solid $gray-400;
            border-radius: 0;
            color: $black;
            font-size: 1rem;

            &:focus {
                border-color: $black;
            }
        }
    }
}
