@import "styles/color";

.waiting-register {
    padding-top: 10rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    .emoji {
        width: 7rem;
    }

    h2 {
        margin-bottom: 1rem;
        color: $gray-900;
    }

    p {
        color: $gray-800;
    }
}
