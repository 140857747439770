$white: #FFFFFF;
$black: #0C2C44;

$gray-900: #212121;
$gray-800: #424242;
$gray-700: #616161;
$gray-600: #757575;
$gray-500: #9e9e9e;
$gray-400: #bdbdbd;
$gray-300: #e0e0e0;
$gray-200: #eeeeee;
$gray-100: #f5f5f5;
$gray-50: #fafafa;


$green-100: #C8E6C9;
$green-200: #A5D6A7;
$green-500: #4CAF50;
$green-800: #2E7D32;

$red-500: #f44336;
$red-600: #e53935;