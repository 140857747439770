.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.instagram-follow-list {
    padding-bottom: 5rem;

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        li {
            width: calc(50% - 0.5rem);
            margin-bottom: 0.5rem;

            &:nth-child(2n) {
                margin-left: 0.5rem;
            }
        }
    }
}
