@import "styles/color";

#main-page {
    padding: 0 2rem;
    padding-bottom: 3rem;

    .main-desc-area {
        padding-top: 55%;

        .title {
            margin-bottom: 5px;
            width: 12rem;
        }

        p {
            font-size: 1.2rem;
            color: $gray-700;
        }
    }

    .move-area {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
    }

    .landing-btn {
        margin-bottom: 7px;
    }

    .landing-btn,
    .move-to-login {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 4rem;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 1.2rem;
        font-weight: 500;
        color: $white;
        background-color: $green-500;
        border-radius: 10px;
        border: none;
    }

    .step-area {
        padding-top: 4rem;
        text-align: center;
    }

    .step-contents {
        margin-top: 3rem;

        h2 {
            line-height: 1.5;
        }

        .img-wrapper {
            margin-top: 2rem;
            margin-bottom: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: $gray-100;
        }

        img {
            width: 80%;
        }

        img + img {
            margin-top: 1rem;
        }
    }

    .button-area {
        display: flex;

        button {
            width: 100%;
        }

        button + button {
            margin-left: 5px;
        }
    }
}
