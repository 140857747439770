.group-list-wrapper {
    .group-list {
        margin-right: -0.5rem;
        
        a {
            width: calc(50% - 0.5rem);
            margin-right: 0.5rem;
        }

    }
}
