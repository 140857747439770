@import "styles/color";

.search-page {
    h3 {
        margin-top: 1rem;
    }

    .search-area {
        display: flex;
        justify-content: space-between;

        input {
            padding-left: 0.5rem;
            width: 80%;
            height: 2.5rem;
            border-radius: 4px;
            border: 1px solid $gray-400;
            font-size: 1rem;
        }

        button {
            width: 18%;
        }
    }

    .history-list {
        padding: 0.6rem 0;
        display: flex;
        overflow-x: auto;

        li {
            flex-shrink: 0;
            border: 1px solid $gray-400;
            border-radius: 1rem;
            padding: 2px 0.7rem;
            font-size: 1.2rem;
            color: $gray-800;
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 6rem;
        }

        li + li {
            margin-left: 0.4rem;
        }
    }

    .search-result-list-wrapper {
        padding-top: 1.3rem;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            li {
                width: calc(50% - 0.5rem);
                margin-bottom: 0.5rem;

                &:nth-child(2n) {
                    margin-left: 0.5rem;
                }
            }
        }
    }
}
